<template>
    <div class="container-fluid">
        <div class="row" >

            <sidebar></sidebar>
            <!-- MAIN CONTENT start -->
            <main role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto col-lg-10">
                <div class="row">
                    <div class="col-12 mb-5">
                        <app-header></app-header>
                        <page-header :page-title="pageData.title" :breadcrumb="pageData.breadcrumb"></page-header>
                    </div>
                </div>
                <!-- Presentation section -->
                <section class="presentation-section  px-0 mx-0 px-xl-5 mx-xl-5  mt-0 mt-md-5 mb-3" v-if="user.currentExam==0">
                    <div class="container-fluid ">
                        <div class="row align-items-center h-100">

                            <div class="col-12">
                                <!-- Presentation subject -->
                                <sk-list v-if="subjectsLoading" :items-count="5" :height="0.06"></sk-list>
                                <div v-else class="accordion" id="presentation-subjects">

                                    <!-- subject 1 -->
                                    <div class="card" v-for="(subject, $index) of subjects">
                                        <div class="card-header" :id="'subjectHeading_' + subject.subjectID + '_' + subject.personID">
                                        <h2 class="mb-0">
                                            <button @click="toggleSubject($index)" class="btn btn-registration" type="button" data-toggle="collapse" :data-target="'#subject_' + subject.subjectID + '_' + subject.personID" aria-expanded="false" :aria-controls="'subject_' + subject.subjectID + '_' + subject.personID">
                                                {{ helpers.getLocalizedField(subject, 'subject') }} - {{ helpers.getLocalizedField(subject, 'Lectors') }}
                                                <span class="toggle-icon"></span>
                                            </button>
                                        </h2>
                                        </div>

                                        <!-- subject 1 TOPICS-->
                                        <div :id="'subject_' + subject.subjectID + '_' + subject.personID" class="collapse" :aria-labelledby="'subjectHeading_' + subject.subjectID + '_' + subject.personID" data-parent="#presentation-subjects">
                                            <div v-if="subjectDataLoaded && !subjectData.already_chosen" class="card-body text-center">
                                                <topic-list @topic-chosen="loadSubjectData"
                                                            v-if="activeSubject && activeSubject.subjectID === subject.subjectID && activeSubject.personID === subject.personID"
                                                            :subject-data="subjectData"
                                                            :subject="subject"></topic-list>
                                            </div>
                                            <div v-if="subjectDataLoaded && subjectData.already_chosen" class="card-body text-center">
                                                <chosen-topic @file-deleted="loadSubjectData"
                                                              @file-uploaded="loadSubjectData"
                                                              @topic-deleted="loadSubjectData"
                                                              v-if="activeSubject && activeSubject.subjectID === subject.subjectID && activeSubject.personID === subject.personID"
                                                              :subject-data="subjectData"
                                                              :subject="subject"></chosen-topic>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <!-- Presentation  -->
                            </div>
                        </div>
                    </div>
                </section>
            </main>

        </div>
    </div>
</template>
<script>
import sidebar from '../components/navbar/sidebar';
import appHeader from '../components/app-header';
import pageHeader from '../components/page/page-header';
import PresentationResource from '../api/presentationResource';
import SkList from '../components/skeletons/sk-list';
import TopicList from '../components/presentations/topic-list';
import ChosenTopic from '../components/presentations/chosen-topic';
import { mapGetters } from 'vuex';
const presentationResource = new PresentationResource();
export default {
  name:'presentations',
  components: { TopicList, ChosenTopic, SkList, sidebar, appHeader, pageHeader },
  computed: {
    pageData() {
      return {
        title: this.$t('presentations.title'),
        breadcrumb: [{
          icon: '',
          title: this.$t('home.title'),
          link: '/',
          isActive: false,
        },
        {
          icon: '',
          title: this.$t('presentations.title'),
          link: '/presentations',
          isActive: true,
        }],
      };
    },

    ...mapGetters('auth', [
      'user',
    ]),

  },

  created() {
    this.loadSubjects();
  },

  data() {
    return {
      subjects: [],
      subjectsLoading: true,
      activeSubject: null,
      subjectData: null,
      subjectDataLoaded: false
    }
  },

  methods: {
    toggleSubject(index) {
      this.subjectDataLoaded = false;
      if(this.activeSubject
        && this.subjects[index].subjectID === this.activeSubject.subjectID
        && this.subjects[index].personID === this.activeSubject.personID) {
        this.activeSubject = null;
      } else {
        this.activeSubject = this.subjects[index];
        this.loadSubjectData();
      }
    },

    loadSubjectData() {
      presentationResource.subject(this.activeSubject.subjectID, this.activeSubject.personID)
      .then(response => {
        this.subjectData = response.data.data;
        this.subjectDataLoaded = true;
      })
      .catch(error => {
        this.helpers.notifyErrorMessage(error);
      })
    },

    loadSubjects() {
      presentationResource.subjects()
      .then(response => {
 
        this.subjects = response.data.data;
      })
      .finally(() => {
        this.subjectsLoading = false;
      })
    },
  }
}
</script>
