<template>
  <div>
    <h2 v-if="(!loading && topics.length === 0) || subjectData.can_upload == 0">{{ $t('presentations.topics-not-found') }}</h2>
    <sk-list v-else-if="loading && subjectData.can_upload == 1" :height="0.04" :items-count="4"></sk-list>
    <div v-else class="row no-gutters justify-content-center">
      <h2 class="col-12">{{ $t('presentations.choose-topic') }}</h2>
      <div v-for="(topic, $topicIdx) of topics" class="col-12">
        <div class="entity d-flex justify-content-between align-items-center">
          <span @click="chooseTopic(topic)" class="f-heavy cursor-pointer">{{ helpers.getLocalizedField(topic, 'topic') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SkList from '../skeletons/sk-list';
  import PresentationResource from '../../api/presentationResource';

  const presentationResource = new PresentationResource();

  export default {
    name: 'topic-list',

    components: { SkList },

    props: ['subject', 'subject-data'],

    mounted() {
      this.loadTopics();
    },

    data() {
      return {
        topics: [],
        loading: false,
      }
    },

    methods: {
      chooseTopic(topic) {
        this.$swal({
          title: "",
          text: this.$t('presentations.confirm-topic-choose'),
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: this.$t('presentations.ok'),
          cancelButtonText: this.$t('presentations.cancel'),
          closeOnConfirm: false,
          closeOnCancel: false
        }).then((result) => {
          if(result && result.isConfirmed) {
            presentationResource.chooseTopic(topic.presentation_topicID, this.subject.personID)
              .then(() => {
                this.$emit('topic-chosen', this.subject);
              })
          }
        });

      },

      loadTopics() {
        this.topics = this.subjectData.topics;
        //this.loading = true;
        // presentationResource.topics(this.subject.subjectID, this.subject.personID)
        //   .then(response => {
        //     this.topics = response.data.data;
        //   })
        //   .catch(err => {
        //     this.helpers.notifyErrorMessage(err);
        //   })
        //   .finally(() => {
        //     this.loading = false;
        //   })
      },
    }
  };
</script>

<style scoped>

</style>
