import axios from 'axios';

export default class PresentationResource {
  subjects() {
    return axios.get('/api/presentations/subjects');
  }

  subject(subjectId, personId) {
    return axios.get('/api/presentations/' + subjectId + '/data/' + personId);
  }

  topics(subjectId, personId) {
    return axios.get('/api/presentations/' + subjectId + '/topics/' + personId);
  }

  chooseTopic(topicId, personId) {
    return axios.post('/api/presentations/topics/' + topicId + '/' + personId);
  }

  deleteTopic(topicId) {
    return axios.delete('/api/presentations/topics/' + topicId);
  }

  uploadFiles(formData, presentationRegistrationId) {
    return axios.post( '/api/presentations/upload/' + presentationRegistrationId, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  }

  deleteFile(presentationRegistrationId, fileType) {
    return axios.delete( '/api/presentations/delete/' + presentationRegistrationId + '?type=' + fileType);
  }

  downloadFile(presentationRegistrationId, fileType) {
    const url = '/api/presentations/download/' + presentationRegistrationId + '?type=' + fileType;

    return axios({
      url: url,
      method: 'GET',
      responseType: 'blob',
    });
  }
}
