<template>
  
  <div class="row no-gutters justify-content-center">
    <div class="col-8 col-md-9 col-lg-12">
      <p class="link-red f-m1 mb-5 pb-4">{{ $t('presentations.upload-warning') }}</p>
    </div>
    <div class="col-8 col-md-9 col-lg-12" v-if="subjectData.books && subjectData.books.length > 0">
     
      <div class="mb-5 p-4 text-black book-list text-left">
        <p>{{ $t('presentations.book-list') }}</p>
        <div v-for="book of subjectData.books">
           
          {{ book.Title }} ({{ book.Authors }})
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="entity d-flex justify-content-between align-items-center">
        
        <span class="f-heavy"> {{ $t('presentations.chosen-topic') }}: {{ helpers.getLocalizedField(subjectData.already_chosen, 'topic') }}</span>
        
        <button v-if="subjectData.can_upload == 1" class="btn btn-red mw-100" @click="deleteTopic">{{ $t('presentations.delete-topic') }}</button>
      </div>
      
    </div>
    <div class="col-12">
      <div class="entity d-flex justify-content-between align-items-center">
        
        <span class="f-heavy">  {{ helpers.getLocalizedField(subjectData.already_chosen, 'pr_description') }}</span>
        
        
      </div>
      
    </div>
    <div class="col-12">
      <div class="heading">
        <span class="f-bold cursor-pointer" @click="chooseDocFile">
          {{ $t('presentations.upload-word') }}
        </span>
        <div v-if="this.doc" class="f-bold float-right">
          <span class="filename">{{ this.doc.name }}</span>
          <span class="cursor-pointer" @click="removeDocFile">
            <img class="img-fluid" src="/static/images/icons/registrations/close.png">
          </span>
        </div>
      </div>
      <div class="entity d-flex justify-content-between align-items-center">
        <input v-if="!subjectData.already_chosen.doc" class="d-none" type="file" id="doc" ref="doc" @change="handleDocFileUpload"/>

        <span v-if="subjectData.already_chosen.doc" class="f-medium">{{ $t('presentations.file-already-uploaded') }}</span>
        <div v-if="subjectData.already_chosen.doc">
          <button @click="downloadFile('doc')" class="btn btn-red mr-2 mr-sm-4">{{ $t('presentations.download') }}</button>
          <button v-if="subjectData.can_upload == 1" @click="deleteFile('doc')" class="btn btn-red">{{ $t('presentations.delete') }}</button>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="heading">
        <span class="f-bold cursor-pointer" @click="choosePptFile">
          {{ $t('presentations.upload-ppt') }}
        </span>
        <div v-if="this.ppt" class="f-bold float-right">
          <span class="filename">{{ this.ppt.name }}</span>
          <span class="cursor-pointer" @click="removePptFile">
            <img class="img-fluid" src="/static/images/icons/registrations/close.png">
          </span>
        </div>
      </div>
      <div class="entity d-flex justify-content-between align-items-center">
        <input v-if="!subjectData.already_chosen.ppt" class="d-none" type="file" id="ppt" ref="ppt" @change="handlePptFileUpload"/>

        <span v-if="subjectData.already_chosen.ppt" class="f-medium">{{ $t('presentations.file-already-uploaded') }}</span>
        <div v-if="subjectData.already_chosen.ppt">
          <button @click="downloadFile('ppt')" class="btn btn-red mr-2 mr-sm-4">{{ $t('presentations.download') }}</button>
          <button v-if="subjectData.can_upload == 1" @click="deleteFile('ppt')" class="btn btn-red">{{ $t('presentations.delete') }}</button>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="mt-5 pt-2">
        <button v-if="subjectData.can_upload == 1" @click="submitFile" :disabled="(!this.doc && !this.ppt) || loading ? 'disabled' : null" class="btn btn-red">{{ $t('presentations.upload') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
  import PresentationResource from '../../api/presentationResource';
  import axios from 'axios';

  const presentationResource = new PresentationResource();

  export default {
    name: 'chosen-topic',

    props: ['subject', 'subject-data'],

    data() {
      return {
        doc: null,
        ppt: null,
        loading: false,
      }
    },

    methods: {
      downloadFile(file) {
        let fileName = ['undefined', 'txt'];
        if(file === 'doc') {
          fileName = this.subjectData.already_chosen.doc.split('.');
        }
        if(file === 'ppt') {
          fileName = this.subjectData.already_chosen.ppt.split('.');
        }

        presentationResource.downloadFile(this.subjectData.already_chosen.presentation_RegistrationID, file)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'UG-Document.' + fileName[1]);
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {
          this.helpers.notifyErrorMessage(error);
        });
      },

      deleteFile(file) {
        presentationResource.deleteFile(this.subjectData.already_chosen.presentation_RegistrationID, file)
        .then(response => {
          this.$emit('file-deleted', this.subject);
        })
        .catch(error => {
          this.helpers.notifyErrorMessage(error);
        })
      },

      removeDocFile() {
        this.doc = null;
      },

      removePptFile() {
        this.ppt = null;
      },

      chooseDocFile() {
        if(this.subjectData.can_upload == 0) return;
        const input = document.getElementById('doc');
        if(input) input.click();
      },

      choosePptFile() {
        if(this.subjectData.can_upload == 0) return;

        const input = document.getElementById('ppt');
        if(input) input.click();
      },

      handleDocFileUpload() {
        this.doc = this.$refs.doc.files[0];
      },

      handlePptFileUpload(){
        this.ppt = this.$refs.ppt.files[0];
      },

      submitFile(){
        let formData = new FormData();
        if(this.doc) formData.append('doc', this.doc);
        if(this.ppt) formData.append('ppt', this.ppt);
       
        this.loading = true;


        
        presentationResource.uploadFiles(formData, this.subjectData.already_chosen.presentation_RegistrationID)
          .then((response) => {

            

            this.$emit('file-uploaded', this.subject);
            this.doc = null;
            this.ppt = null;
          })
          .catch((error) => {
            this.helpers.notifyErrorMessage(error);
          })
          .finally(() => {
            this.loading = false;
          });
      },

      deleteTopic() {
        this.$swal({
          title: "",
          text: this.$t('presentations.confirm-topic-delete'),
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: this.$t('presentations.ok'),
          cancelButtonText: this.$t('presentations.cancel'),
          closeOnConfirm: false,
          closeOnCancel: false
        }).then((result) => {
          if(result && result.isConfirmed) {
            presentationResource.deleteTopic(this.subjectData.already_chosen.presentation_topicID)
            .then(() => {
              this.$emit('topic-deleted', this.subject);
            });
          }
        });
      }
    }
  };
</script>

<style scoped>
  .book-list {
    font-size: 16px;
    color: var(--text);
  }
</style>
